<template>
  <div class="container-fluid">
    <form class="row" role="form">
      <div class="col-md-12">
        <div class="form-group row">
          <div class="col-12">
            <label class="row">{{ $t("url") }}</label>
            <input
              type="text"
              class="row form-control"
              v-model="url"
              name="url"
              v-validate="'required'"
            />
            <small v-show="errors.has('url')" class="row text-danger">
              {{ $t("field_required") }}
            </small>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-6">
            <label class="row">{{ $t("size") }} (px)</label>
            <input
              type="number"
              class="row form-control"
              v-model="size"
              name="size"
              v-validate="'required'"
            />
            <small v-show="errors.has('size')" class="row text-danger">
              {{ $t("field_required") }}
            </small>
          </div>
          <div class="col-6">
            <label class="row">{{ $t("margin") }}</label>
            <input
              type="number"
              class="row form-control"
              v-model="margin"
              name="margin"
              v-validate="'required'"
            />
            <small v-show="errors.has('margin')" class="row text-danger">
              {{ $t("field_required") }}
            </small>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12 text-right">
            <a
              class="btn btn-primary text-white mr-3 mt-3"
              @click="downloadQrCode()"
              >{{ $t("generate") }}</a
            >
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "GenerateForm",
  data() {
    return {
      margin: 1,
      size: 60,
      url: ""
    };
  },
  created() {
    this.$emit("loading", false);
  },
  methods: {
    downloadQrCode() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit("loading", true);
          const prepareDataToShow = {
            url: this.url,
            size: this.size,
            margin: this.margin
          };
          this.$http
            .customRequest({
              url: "qr-code/generate/url",
              method: "POST",
              data: prepareDataToShow,
              responseType: "blob"
            })
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              let fileName = `grCode-${Date.now()}.png`;
              link.setAttribute("download", fileName);
              document.body.appendChild(link);
              link.click();

              this.$toastr.success("Pobrany QrCode");
              this.$emit("loading", false);
            })
            .catch(err => {
              this.$toastr.error(err);
              this.$emit("loading", false);
            });
        }
      });
    }
  }
};
</script>
