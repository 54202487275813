<template>
  <div class="row">
    <div class="col-12">
      <ibox :title="`${$t('qr-code-generate')}`">
        <template slot="content" slot-scope="{ setLoading }">
          <GenerateForm @loading="setLoading"> </GenerateForm>
        </template>
      </ibox>
    </div>
  </div>
</template>

<script>
import Ibox from "@/components/base/Ibox";
import GenerateForm from "@/components/QrCode/GenerateForm.vue";

export default {
  name: "QrCodeGenerate",
  components: {
    GenerateForm,
    Ibox
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
